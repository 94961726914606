import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import BarChartIcon from '@mui/icons-material/BarChart';
import MergeIcon from '@mui/icons-material/Merge';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import TimelineIcon from '@mui/icons-material/Timeline';
import Link from "next/link";
import {Tooltip} from "@mui/material";

export const mainListItems = (
    <React.Fragment>
        <Link href={'/'} passHref>
            <Tooltip title="Dashboard" placement="right">
                <ListItemButton component="a">
                    <ListItemIcon>
                        <DashboardIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard"/>
                </ListItemButton>
            </Tooltip>
        </Link>
        <Link href={'/tiempo-real'} passHref>
            <Tooltip title="Ruta Tiempo Real" placement="right">
                <ListItemButton component="a">
                    <ListItemIcon>
                        <MyLocationIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Ruta Tiempo Real"/>
                </ListItemButton>
            </Tooltip>
        </Link>
        <Link href={'/v2/tiempo-real'} passHref>
            <Tooltip title="Tracking" placement="right">
                <ListItemButton component="a">
                    <ListItemIcon>
                        <TimelineIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Ruta Tiempo Real"/>
                </ListItemButton>
            </Tooltip>
        </Link>

        <Link href={'/tramos'} passHref>
            <Tooltip title="Conteo por tramos" placement="right">
                <ListItemButton component="a">
                    <ListItemIcon>
                        <MergeIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Conteo por tramos"/>
                </ListItemButton>
            </Tooltip>
        </Link>
        <Link href={'/secciones'} passHref>
            <Tooltip title="Unidades" placement="right">
                <ListItemButton component="a">
                    <ListItemIcon>
                        <DirectionsBusIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Unidades"/>
                </ListItemButton>
            </Tooltip>
        </Link>
        <Link href={'/tramos'} passHref>
            <ListItemButton component="a">
                <ListItemIcon>
                    <BarChartIcon/>
                </ListItemIcon>
                <ListItemText primary="Reportes"/>
            </ListItemButton>
        </Link>
    </React.Fragment>
);

export const secondaryListItems = (
    <React.Fragment>
        {/*<ListSubheader component="div" inset>*/}
        {/*    Administrativo*/}
        {/*</ListSubheader>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <AssignmentIcon/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Respaldos"/>*/}
        {/*</ListItemButton>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <AssignmentIcon/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Usuarios"/>*/}
        {/*</ListItemButton>*/}
        {/*<ListItemButton>*/}
        {/*    <ListItemIcon>*/}
        {/*        <AssignmentIcon/>*/}
        {/*    </ListItemIcon>*/}
        {/*    <ListItemText primary="Unidades"/>*/}
        {/*</ListItemButton>*/}

    </React.Fragment>
);